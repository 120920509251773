// MyHome.js
import React, { useEffect, useState } from "react";
import {
  Container,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { decodeTokenFromStorage } from "../utils/token";
import axiosInstance from "../utils/apiCall";
import ElectionCardVoters from "../components/ElectionCardVoters";

const drawerWidth = 240;

const MyHome = () => {
  const userDetails = decodeTokenFromStorage();
  const [loading, setLoading] = useState(false);
  const [electionDetails, setElectionDetails] = useState("");

  useEffect(() => {
    const fetchMyElection = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(
          `/electionvoterdetails/${userDetails?.electionid}`
        );
        setElectionDetails(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchMyElection();
  }, []);
  return (
    <div>
      <CssBaseline />
      <Drawer variant="permanent">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Dashboard
          </Typography>
        </Toolbar>
        <List>
          <ListItem>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AddCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Add Election" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>

      <Container
        component="main"
        sx={{ ml: `${drawerWidth}px`, bgcolor: "gray" }}>
        <Toolbar />
        <div>
          <Typography variant="h4" sx={{ mt: 2 }}>
            Welcome to My {userDetails?.fullname}
          </Typography>
          {/* Add your other components and content here */}
        </div>
        <ElectionCardVoters electionDetails={electionDetails} />
      </Container>

      <footer>
        <Typography variant="body2" color="textSecondary" align="center">
          &copy; 2024 Your Company Namexx
        </Typography>
      </footer>
    </div>
  );
};

export default MyHome;
