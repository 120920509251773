import React, { useState, useEffect } from "react";
import md5 from "md5";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../utils/apiCall";
import { setToken } from "../utils/token";

const Vt = () => {
  const { allbit, longbit } = useParams();
  const nav = useNavigate();
  const [codeVote, voterId] = [allbit.slice(0, 8), allbit.slice(8)];
  const [error, setError] = useState(null);

  const longConfirm = md5(codeVote);

  const checkUrl = async () => {
    if (longConfirm === longbit) {
      axiosInstance
        .post("/voterslogin", { voterId, codeVote })
        .then((response) => {
          setToken(response?.data?.token);
          nav(`/votesession/${response.data.electionid}`);
        })
        .catch((error) => {
          setError(error?.response?.data?.message);
          nav("/voteissue", {
            state: { errorMessage: error?.response?.data?.message },
          });
        });
    } else {
      //nav("/browseerror");
    }
  };

  useEffect(() => {
    checkUrl();
  }, [longConfirm, longbit]);

  return (
    error && (
      <div>
        <p>NOT VALID</p>
      </div>
    )
  );
};

export default Vt;
