import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useElectionDetails,
  useFetchResults,
  useFetchVoteLog,
  useElectionReport,
} from "../hooks/fetchApis";
import ElectionReport from "./ElectionReport";
import VoteLog from "./VoteLog";
import VoteResults from "./VoteResults";
import TabPanel from "../components/TabPanel";

function ElectionResults() {
  const { electionid } = useParams();
  const [loading, setLoading] = useState(false);

  const {
    data: resultRecords,
    loading: isloading6,
    errorStatus6,
  } = useFetchResults(electionid);

  const {
    data: electiondetails,
    loading: isloading,
    errorStatus,
  } = useElectionDetails(electionid);

  const {
    data: voteLog,
    loading: isloading4,
    errorStatus4,
  } = useFetchVoteLog(electionid);

  const {
    data: reportRecords,
    loading: isloading7,
    errorStatus7,
  } = useElectionReport(electionid);

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Typography sx={styles.pageTitle} variant="h5">
        {electiondetails?.electionname}
      </Typography>

      <Box sx={styles.tabHeader}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="CHART" id="tab-0" />
          <Tab label="FIGURES" id="tab-1" />
          <Tab label="REPORT" id="tab-2" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} sx={{}}>
        <VoteResults positions={resultRecords} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <VoteLog voteLog={voteLog} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ElectionReport reportRecords={reportRecords} />
      </TabPanel>
    </Box>
  );
}

export default ElectionResults;

/**
 * @type {import("@mui/material").SxProps}
 */

const styles = {
  pageTitle: {
    mb: 2,
  },
  tabHeader: {
    borderBottom: 1,
    borderColor: "divider",
  },
  rowContainer: {
    width: "100%",
    maxWidth: 900,
    border: 1,
    borderColor: "neutral.medium",
    borderRadius: 1,
    mt: 2,
    p: 2,
    display: "flex",
    alignItems: "flex-start",
  },
  rowIcon: {
    fontSize: 40,
    color: "neutral.normal",
  },
  secondColumn: {
    ml: 1,
  },
  rowLink: {
    ml: "auto",
  },
};
