import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Container,
  Grid,
  CardMedia,
  Divider,
  CircularProgress,
} from "@mui/material/";
import axiosInstance from "../utils/apiCall";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const VoteSession = () => {
  const nav = useNavigate();
  const { electionid } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [skipVotes, setSkipVotes] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [positionsData, setPositionsData] = useState([]);
  const [selectedVotes, setSelectedVotes] = useState([]);
  const [currentPositionIndex, setCurrentPositionIndex] = useState(0);

  useEffect(() => {
    const fetchVotingStatus = async () => {
      try {
        const response = await axiosInstance.get(`/votingstatus/${electionid}`);
        if (response.data.voted) {
          toast.error("Voting more than once is not allowed.");
          nav(`/votethanks/${electionid}`);
        }
      } catch (error) {
        setErrorMessage(error?.response?.data?.message);
        toast.error("Error! Try again");
      }
    };
    fetchVotingStatus();
  }, [electionid]);

  useEffect(() => {
    const fetchElectionData = async () => {
      try {
        const response = await axiosInstance.get(`/electiondata/${electionid}`);
        setPositionsData(response.data);
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        if (errorMessage) nav("/voteissue", { state: { errorMessage } });
      }
    };
    fetchElectionData();
  }, [electionid]);

  const handleNext = () => {
    const selectedCandidates = positionsData[
      currentPositionIndex
    ].candidates.filter((candidate) => candidate.selected);
    if (
      selectedCandidates.length ===
        positionsData[currentPositionIndex].votenumber ||
      skipVotes
    ) {
      const selectedVote = selectedCandidates.map((candidate) => ({
        positionid: positionsData[currentPositionIndex].positionid,
        candidateid: candidate.candidateid,
      }));

      setSelectedVotes((prevVotes) => {
        const updatedVotes = prevVotes.filter(
          (vote) =>
            vote.positionid !== positionsData[currentPositionIndex].positionid
        );
        return [...updatedVotes, ...selectedVote];
      });

      setCurrentPositionIndex((prevIndex) => prevIndex + 1);
    } else {
      alert(
        `Please select ${positionsData[currentPositionIndex].votenumber} candidate(s) or use the Skip button.`
      );
    }
  };

  const handleSkip = () => {
    setCurrentPositionIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrevious = () => {
    setCurrentPositionIndex((prevIndex) => prevIndex - 1);
  };

  const handleBackToStart = () => {
    const updatedPositionsData = positionsData.map((position) => ({
      ...position,
      candidates: position.candidates.map((candidate) => ({
        ...candidate,
        selected: false,
      })),
    }));
    setPositionsData(updatedPositionsData);
    setCurrentPositionIndex(0);
    setSelectedVotes([]);
  };

  const handleVote = (positionIndex, candidateIndex) => {
    const updatedPositionsData = [...positionsData];
    const votenumber = updatedPositionsData[positionIndex].votenumber;

    const candidate =
      updatedPositionsData[positionIndex].candidates[candidateIndex];

    if (votenumber === 1) {
      updatedPositionsData[positionIndex].candidates.forEach((c, i) => {
        c.selected = i === candidateIndex;
      });
    } else {
      const selectedCount = updatedPositionsData[
        positionIndex
      ].candidates.filter((c) => c.selected).length;

      if (selectedCount < votenumber || candidate.selected) {
        candidate.selected = !candidate.selected;
      } else {
        updatedPositionsData[positionIndex].candidates.forEach((c) => {
          c.selected = false;
        });
        candidate.selected = true;
      }
    }

    setPositionsData(updatedPositionsData);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await axiosInstance.post(`/createvotes/${electionid}`, {
        votes: selectedVotes,
      });
      setIsLoading(false);
      toast.success("Congratulations, you have voted successfully");
      nav(`/votethanks/${electionid}`);
    } catch (error) {
      toast.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  return (
    <Container>
      {currentPositionIndex < positionsData.length ? (
        <Grid
          container
          spacing={3}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" component="h2" gutterBottom>
              {positionsData[currentPositionIndex].positionname}
            </Typography>
            <Card style={{ maxWidth: 400, margin: "auto" }}>
              <CardContent>
                {positionsData[currentPositionIndex].candidates.map(
                  (candidate, index) => (
                    <div key={candidate.candidateid}>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: "25px" }}
                      >
                        <Grid item xs={4}>
                          <CardMedia
                            component="img"
                            alt={candidate.fullname}
                            height="100%"
                            width="100%"
                            image={candidate.profilepicture}
                            style={{
                              borderRadius: "50%",
                              border: "2px solid #ccc",
                            }}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Typography style={{ marginTop: "8px" }}>
                            {candidate.fullname}
                          </Typography>
                          <Button
                            variant="contained"
                            color={candidate.selected ? "secondary" : "primary"}
                            onClick={() =>
                              handleVote(currentPositionIndex, index)
                            }
                            style={{ marginTop: "15px" }}
                          >
                            {candidate.selected ? "Selected" : "Select"}
                          </Button>
                        </Grid>
                      </Grid>
                      {index <
                        positionsData[currentPositionIndex].candidates.length -
                          1 && <Divider style={{ margin: "10px" }} />}
                    </div>
                  )
                )}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handlePrevious}
                      disabled={currentPositionIndex === 0}
                    >
                      Previous
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      {currentPositionIndex === positionsData.length - 1
                        ? "Submit"
                        : "Next"}
                    </Button>
                    {skipVotes && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleSkip}
                      >
                        Skip
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          spacing={3}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" component="h2" gutterBottom>
              Submit Votes
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleBackToStart}
                >
                  Start all over
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  color="secondary"
                  disabled={isLoading}
                  sx={{ mt: 3, mb: 2 }}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Submit finally"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default VoteSession;
