import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Avatar,
  Divider,
  Paper,
} from "@mui/material";

function CandidateCard({ candidate }) {
  return (
    <Card sx={{ maxWidth: 200, margin: 2 }}>
      <CardContent>
        <Avatar alt={candidate.fullname} src={candidate.captionimage} />
        <Typography variant="body2" color="text.secondary">
          {candidate.fullname}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Votes: {candidate.totalVotes}
        </Typography>
      </CardContent>
    </Card>
  );
}

function ElectionReport({ reportRecords }) {
  const paperStyle = {
    padding: "16px",
    borderRadius: "8px",
    marginBottom: "16px",
  };

  const labelStyle = {
    fontWeight: "bold",
    marginRight: "8px",
    display: "block",
    marginBottom: "15px",
  };
  const labelStyle2 = {
    fontWeight: "bold",
    marginRight: "8px",
    display: "block",
    marginBottom: "15px",
    marginTop: 10,
  };

  return (
    <Box>
      <Typography variant="h4" align="center">
        Online Voting Report
      </Typography>
      <Typography variant="h4" align="center" sx={{ color: "gray" }}>
        {reportRecords?.election?.electionname}
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={reportRecords?.election?.captionimage}
          alt="Election Banner"
          style={{ width: "10%" }}
        />
      </div>

      <Paper elevation={3} style={paperStyle}>
        <Typography variant="h6" gutterBottom>
          Settings
        </Typography>

        <Box>
          <Typography style={labelStyle}>
            Date and Time Election Started:
          </Typography>
          <Typography>{reportRecords?.election?.startdate}</Typography>
        </Box>

        <Box>
          <Typography style={labelStyle}>
            Date and Time Election Ended:
          </Typography>
          <Typography>{reportRecords?.election?.enddate}</Typography>
        </Box>

        <Box>
          <Typography style={labelStyle}>Date Published:</Typography>
          <Typography>{reportRecords?.election?.datepublished}</Typography>
        </Box>

        <Box>
          <Typography style={labelStyle}>Total Eligible Voters:</Typography>
          <Typography>{reportRecords?.election?.totalvoters}</Typography>
        </Box>

        <Box>
          <Typography style={labelStyle}>Total Unique Voters:</Typography>
          <Typography>
            {reportRecords?.election?.uniquevotercount.length}
          </Typography>
        </Box>

        <Box>
          <Typography style={labelStyle}>
            Voters Can See Result Link:
          </Typography>
          <Typography>
            {reportRecords?.election?.votersresultlink ? "Yes" : "No"}
          </Typography>
        </Box>
        <Box>
          <Typography style={labelStyle}>
            Voters Can Skip Voting Some Positions:
          </Typography>
          <Typography>
            {reportRecords?.election?.skipvotes ? "Yes" : "No"}
          </Typography>
        </Box>
      </Paper>

      <Divider sx={{ marginY: 2 }} />

      {reportRecords &&
        reportRecords.positions.map((position) => (
          <Box key={position.id} sx={{ marginX: 20, marginY: 3 }}>
            <Typography variant="h6">{`Positions: ${position?.positionname}`}</Typography>
            <Grid container spacing={2}>
              {position?.candidates?.map((candidate) => (
                <Grid item key={candidate?.id}>
                  <CandidateCard candidate={candidate} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}

      <Divider sx={{ marginY: 2 }} />

      <Box sx={{ marginTop: 2, textAlign: "center" }}>
        <Typography variant="body2">Signature: _________________</Typography>
        <Typography variant="body2">Date: _________________</Typography>
      </Box>

      <Typography variant="body2" sx={{ textAlign: "center", marginTop: 2 }}>
        (c) voterz.michofat.com
      </Typography>
    </Box>
  );
}

export default ElectionReport;
