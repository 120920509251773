import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  TextField,
  Container,
  Grid,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material/";
import axiosInstance from "../utils/apiCall";
import { forgotPasswordValidationSchema } from "../utils/validation";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(forgotPasswordValidationSchema),
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true); // Start CircularProgress

      // Simulate an API call delay
      const response = await axiosInstance.put(`/requestforgotcode`, data);
      alert(response.data.message);
    } catch (error) {
      alert(error.response.data.message);
    } finally {
      setIsLoading(false); // Stop CircularProgress
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Reset Code
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box noValidate sx={{ mt: 1 }}>
            <TextField
              label="Email"
              type="text"
              {...register("email")}
              variant="outlined"
              margin="normal"
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isSubmitting}
              sx={{ mt: 3, mb: 2 }}>
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </form>

        <Grid container>
          <Grid item xs>
            <Link to="/adminlogin" variant="body2">
              Back to Sign In
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
