import React from "react";
import { AppBar, Box, Toolbar, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import { decodeTokenFromStorage } from "../utils/token";

const Header = () => {
  const nav = useNavigate();
  const user = decodeTokenFromStorage();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ bgcolor: "green" }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Box sx={{ display: "flex" }}>
              <img
                onClick={() => nav("/")}
                src="./assets/icon.jpeg"
                width={30}
                height={30}
                alt="icon1"
              />
            </Box>
            {user && user?.adminid && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 5,
                  alignItems: "center",
                }}>
                <Typography
                  onClick={() => nav("/dashboard")}
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1, textAlign: "right", cursor: "pointer" }}>
                  Dashboard
                </Typography>
              </Box>
            )}

            {user && (
              <Typography
                onClick={() => nav("/dashboard")}
                variant="h8"
                component="div"
                sx={{ flexGrow: 1, textAlign: "right", cursor: "pointer" }}>
                {"Hi" + " " + user?.fullname}
              </Typography>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
