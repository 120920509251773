import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/apiCall";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

//      const response = await axiosInstance.get(`/myelections`);
export const useMyElections = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorstatus, setErrorStatus] = useState(null);
  const nav = useNavigate();

  useEffect(() => {
    const fetchMyElections = async () => {
      try {
        const response = await axiosInstance.get(`/myelections`);
        setData(response.data);
      } catch (error) {
        setErrorStatus(error?.response?.status);
      } finally {
        setLoading(false);
      }
    };
    fetchMyElections();
  }, [errorstatus, nav]);

  return { data, loading, errorstatus };
};

export const useElectionDetails = (electionid) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorstatus, setErrorStatus] = useState(null);
  const nav = useNavigate();

  useEffect(() => {
    const fetchElectionDetails = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/electiondetails/${electionid}`
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        setErrorStatus(error?.response?.status);
      } finally {
        setLoading(false);
      }
    };
    fetchElectionDetails();
  }, [electionid, errorstatus, nav]);

  return { data, loading, errorstatus };
};

export const usePositions = (electionid) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorstatus, setErrorStatus] = useState(null);
  const nav = useNavigate();

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`/positions/${electionid}`);

        setData(response.data);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchPositions();
  }, [electionid, errorstatus, nav]);
  return { data, loading, errorstatus };
};

export const usePositionDetails = (positionid, electionid) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorstatus, setErrorStatus] = useState(null);
  const nav = useNavigate();

  useEffect(() => {
    const fetchPositionDetails = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/positiondetails/${positionid}/${electionid}`
        );

        setData(response.data);
        setLoading(false);
      } catch (error) {
        setErrorStatus(error?.response?.status);
      } finally {
        setLoading(false);
      }
    };
    fetchPositionDetails();
  }, [positionid, electionid, errorstatus, nav]);

  return { data, loading, errorstatus };
};

export const useCandidates = (positionid) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorstatus, setErrorStatus] = useState(null);
  const nav = useNavigate();

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`/candidates/${positionid}`);

        setData(response.data);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchCandidates();
  }, [positionid, errorstatus, nav]);
  return { data, loading, errorstatus };
};

export const useVoters = (electionid) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorstatus, setErrorStatus] = useState(null);
  const nav = useNavigate();

  useEffect(() => {
    const fetchVoters = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`/positions/${electionid}`);

        setData(response.data);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchVoters();
  }, [electionid, errorstatus, nav]);
  return { data, loading, errorstatus };
};

export const useGetVoters = (electionid, page, limit) => {
  return useQuery({
    queryKey: ["allvoters", page, limit], // Include page in the queryKey
    queryFn: () =>
      axiosInstance
        .get(`/electionvoters/${electionid}?page=${page}&pageSize=${limit}`)
        .then((res) => res.data),
  });
};

export const useVoterStat = (electionid) => {
  //  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorstatus, setErrorStatus] = useState(null);
  const [theTotalCodeSent, setTheTotalCodeSent] = useState("");
  const [theTotalVoters, setTheTotalVoters] = useState("");
  const nav = useNavigate();

  useEffect(() => {
    const fetchVoterStat = async () => {
      try {
        const response = await axiosInstance.get(`/votersstat/${electionid}`);
        setTheTotalVoters(response?.data?.totalvoters);
        setTheTotalCodeSent(response?.data?.totalcodesent);
      } catch (error) {
        setErrorStatus(error?.response?.status);
      } finally {
        setLoading(false);
      }
    };
    fetchVoterStat();
  }, [electionid, errorstatus, nav]);

  return { theTotalCodeSent, theTotalVoters, loading, errorstatus };
};

export const useFetchResults = (electionid) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorstatus, setErrorStatus] = useState(null);
  const nav = useNavigate();

  useEffect(() => {
    const fetchResults = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(
          `/electionresult/${electionid}`
        );

        setData(response.data);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchResults();
  }, [electionid, errorstatus, nav]);

  return { data, loading, errorstatus };
};

export const useFetchVoterResults = (electionid) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorstatus, setErrorStatus] = useState(null);
  const nav = useNavigate();

  useEffect(() => {
    const fetchVotersResults = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(
          `/voterselectionresults/${electionid}`
        );

        setData(response.data);
      } catch (error) {
        setLoading(false);
        nav("/browseerror");
      }
    };
    fetchVotersResults();
  }, [electionid, errorstatus, nav]);

  return { data, loading, errorstatus };
};

export const useFetchVoteLog = (electionid) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorstatus, setErrorStatus] = useState(null);
  const nav = useNavigate();

  useEffect(() => {
    const fetchVoteLog = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`/votelog/${electionid}`);
        setData(response.data);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchVoteLog();
  }, [electionid, errorstatus, nav]);

  return { data, loading, errorstatus };
};

export const useElectionReport = (electionid) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorstatus, setErrorStatus] = useState(null);
  const nav = useNavigate();

  useEffect(() => {
    const fetchElectionReport = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(
          `/electionreport/${electionid}`
        );

        setData(response.data);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchElectionReport();
  }, [electionid, errorstatus, nav]);

  return { data, loading, errorstatus };
};
