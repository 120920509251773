import React, { useState, useEffect } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axiosInstance from "../utils/apiCall";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";

const PositionTable = () => {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const { electionid } = useParams();
  const [myPositions, setMyPositions] = useState([]);
  const [positionData, setPositionData] = useState({
    positionname: "",
    votersinstruction: "",
    positionid: "",
  });

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await axiosInstance.get(`/positions/${electionid}`);
        setMyPositions(response.data);
      } catch (error) {
        // nav("/error404");
      }
    };

    fetchPositions();
  }, [electionid]);

  const handleEditClick = (position, positionid) => {
    const selectedPositionData = myPositions.find(
      (pos) => pos.positionname === position
    );
    setSelectedPosition(position);

    setPositionData((prevData) => ({
      ...prevData,
      positionname: selectedPositionData.positionname || "",
      votersinstruction: selectedPositionData.votersinstruction || "",
      positionid,
    }));

    setEditModalOpen(true);
  };

  const handleDeleteClick = (position, dat) => {
    setSelectedPosition(position);
    setSelectedId(dat?.positionid);
    setDeleteModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDeleteConfirm = async () => {
    // Handle delete logic here

    try {
      setIsLoading(true);
      // Add logic to send data to the endpoint
      const response = await axiosInstance.delete(
        `/deleteposition/${selectedId}/${electionid}`
      );
      setIsLoading(false);
      toast.success(response.data);

      // Close the modal
      setEditModalOpen(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Delete failed");
    }

    setDeleteModalOpen(false);

    setDeleteModalOpen(false);
  };

  const handleInputChange = (e) => {
    setPositionData({
      ...positionData,
      [e.target.name]: e.target.value,
    });
  };
  const handleEditSubmit = async () => {
    try {
      setIsLoading(true);
      // Add logic to send data to the endpoint
      const response = await axiosInstance.patch(
        `/editposition/${positionData.positionid}/${electionid}`,
        {
          positionname: positionData.positionname,
          votersinstruction: positionData.votersinstruction,
        }
      );
      setIsLoading(false);
      toast.success(response.data);

      // Close the modal
      setEditModalOpen(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Edit failed");
    }
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S/N</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Contestants</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {myPositions.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row.positionname}</TableCell>
                <TableCell>
                  <Button
                    component={RouterLink}
                    to={`/addcandidate/${electionid}/${row.positionid}`}
                    color="primary">
                    {row.contestants}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() =>
                      handleEditClick(row.positionname, row.positionid)
                    }>
                    <EditIcon />
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => handleDeleteClick(row.positionname, row)}>
                    <DeleteIcon sx={{ color: "red" }} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Modal */}
      <Dialog open={editModalOpen} onClose={handleEditModalClose}>
        <DialogTitle>Edit Position</DialogTitle>
        <DialogContent>
          <form>
            <input
              type="hidden"
              name="positionid"
              value={positionData.positionid}
            />
            <TextField
              label="Position Name"
              name="positionname"
              value={positionData.positionname}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Voters Instructions"
              name="votersinstruction"
              value={positionData.votersinstruction}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditModalClose}>Cancel</Button>
          <Button onClick={handleEditSubmit}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Modal */}
      <Dialog open={deleteModalOpen} onClose={handleDeleteModalClose}>
        <DialogTitle>Delete Position</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the position "{selectedPosition}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteModalClose}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PositionTable;
