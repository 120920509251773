import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { decodeTokenFromStorage } from "../utils/token";

export default function Home() {
  const centerContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // Set the height to 100% of the viewport height
  };
  //https://voterz.michofat.com/vt/475830933e03ea79-22c1-4292-90b9-aea9990f87fd/70d69b3ae4390c1e3a8ea3ae01b453ea
  const buttonContainerStyle = {
    display: "flex",
    gap: "16px", // Adjust the gap between buttons as needed
  };
  const user = decodeTokenFromStorage();
  return (
    <Box sx={centerContainerStyle}>
      <Box sx={buttonContainerStyle}>
        <Link to="/dashboard">
          <Button variant="contained">Manage eVoting</Button>
        </Link>
      </Box>
      {/* <Link to="/voterslogin">
        <Button variant="outlined">Cast your Vote</Button>
      </Link> */}
    </Box>
  );
}
