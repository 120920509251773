import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Card,
  CardContent,
  InputLabel,
  Input,
  FormControl,
} from "@mui/material/";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../utils/apiCall";
import { newRequestUpload } from "../utils/apiStorage";
import { toast } from "react-toastify";
import CandidateTable from "../components/CandidateTable";

const AddCandidate = () => {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { electionid, positionid } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [electionDetails, setElectionDetails] = useState("");
  const [positionDetails, setPositionDetails] = useState("");

  useEffect(() => {
    const fetchMyElection = async () => {
      try {
        const response = await axiosInstance.get(
          `/electiondetails/${electionid}`
        );
        setElectionDetails(response.data);
      } catch (error) {
        // nav("/error404");
      }
    };
    fetchMyElection();
  }, [electionid]);

  useEffect(() => {
    const fetchMyPosition = async () => {
      try {
        const response = await axiosInstance.get(
          `/positiondetails/${positionid}/${electionid}`
        );
        setPositionDetails(response.data);
      } catch (error) {
        //  nav("/error404");
      }
    };
    fetchMyPosition();
  }, [positionid]);

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      fullname: "",
      profile: "",
    },
  });

  const handleImageChange = async (e) => {
    // Update selectedImage state when a new image is selected
    setSelectedImage(e.target.files[0]);
  };

  const getURL = async () => {
    const formData = new FormData();
    formData.append("myimage", selectedImage);

    return newRequestUpload.put(`/scavengeimage`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const postBackend = async (data) => {
    return axiosInstance.post(
      `/addcandidate/${electionid}/${positionid}`,
      data
    );
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const storageServerResponse = await getURL();
      const captionimage = storageServerResponse.data;

      data.captionimage = captionimage;
      await postBackend(data);
      toast.success("Candidate added successfully");
      setSelectedImage(null);
      reset();
      // nav("/dashboard");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" component="h2" gutterBottom>
            {electionDetails?.electionname}
          </Typography>
          <Typography variant="h4" component="h2" gutterBottom>
            {positionDetails?.positionname}
          </Typography>
          <CandidateTable electionid={electionid} positionid={positionid} />
          <Typography variant="h4" component="h2" gutterBottom>
            Add Candidate
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card variant="outlined" style={{ margin: "10px 0" }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      {...register("fullname")}
                      label="Candidate Name"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                  {selectedImage && (
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      alt="Selected"
                      style={{ maxWidth: "20%", marginTop: "10px" }}
                    />
                  )}
                  <Grid item xs={12}>
                    <InputLabel htmlFor="passport">Select Passport</InputLabel>
                    <TextField
                      type="file"
                      fullWidth
                      onChange={handleImageChange}
                      accept=".jpg, .jpeg, .png"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      {...register("profile")}
                      label="Candidate Profile"
                      placeholder="Candidate Profile"
                      style={{ width: "100%" }}
                      rows={5}
                      multiline
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3, mb: 2 }}>
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddCandidate;
