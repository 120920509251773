import React, { useEffect } from "react";
import Chart from "chart.js/auto";

const VoteResult = ({ positions }) => {
  useEffect(() => {
    positions?.forEach((position) => {
      const canvasId = `chart-${position?.positionid}`;

      // Create a bar chart for each position
      const ctx = document.getElementById(canvasId).getContext("2d");
      new Chart(ctx, {
        type: "bar",
        data: {
          labels: position?.scores?.map((score) => score?.candidatename),
          datasets: [
            {
              label: "Total Votes",
              data: position?.scores?.map((score) => score?.totalvotes),
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    });
  }, [positions]);

  return (
    <div>
      {positions?.map((position) => (
        <div key={position?.positionid}>
          <h2>{position.position}</h2>
          <canvas
            id={`chart-${position?.positionid}`}
            width="400"
            height="200"></canvas>
        </div>
      ))}
    </div>
  );
};

export default VoteResult;
