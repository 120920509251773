import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";

const VoteIssue = (props) => {
  const location = useLocation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}>
      <Typography variant="h1" color="success" gutterBottom>
        Sorry!
      </Typography>
      <Typography variant="h5" gutterBottom>
        {location.state.errorMessage}
      </Typography>
    </Box>
  );
};

export default VoteIssue;
