// PublishElection.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../utils/apiCall";
import { toast } from "react-toastify";

const PublishElection = () => {
  const nav = useNavigate();
  const { electionid } = useParams();
  const [electionDetails, setElectionDetails] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchMyElection = async () => {
      try {
        const response = await axiosInstance.get(
          `/electiondetails/${electionid}`
        );
        setElectionDetails(response.data);
      } catch (error) {
        // nav("/error404");
      }
    };

    fetchMyElection();
  }, [electionid]);

  const handlePublishNow = async () => {
    try {
      setIsLoading(true); // Start CircularProgress

      const response = await axiosInstance.patch(
        `/publishelection/${electionid}`
      );
      toast.success(response.data.message);
      //  nav("/adminlogin");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false); // Stop CircularProgress
    }
  };

  return (
    <Container>
      <Card sx={{ maxWidth: 600, margin: "auto", marginTop: 4 }}>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Election Properties
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Election Name:</strong> {electionDetails?.electionname}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Election Acronym:</strong>{" "}
            {electionDetails?.electionacronym}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Election Caption Image:</strong>{" "}
            <CardMedia
              component="img"
              alt="green iguana"
              height="140"
              image={electionDetails?.captionimage}
            />
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Start Date and Time:</strong> {electionDetails?.startdate}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>End Date and Time:</strong> {electionDetails?.enddate}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>General Instruction:</strong>{" "}
            {electionDetails?.generalinstruction}
          </Typography>

          <Box mt={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePublishNow}
              disabled={electionDetails?.published}>
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                " Publish Now"
              )}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PublishElection;
