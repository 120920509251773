import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Card,
  CardContent,
} from "@mui/material";

function VoteLog({ voteLog }) {
  const formatDateTime = (dateTimeString) => {
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const formattedDateTime = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );
    return formattedDateTime;
  };

  return (
    <Box>
      {voteLog &&
        voteLog.map((position) => (
          <Card key={position.positionid} sx={{ marginBottom: 2 }}>
            <CardContent>
              <Typography variant="h6">
                {position.positionname}{" "}
                <span>
                  <b>({position.voterCount})</b>
                </span>
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>S/N</TableCell>
                      <TableCell>Voter</TableCell>
                      <TableCell>Profile</TableCell>
                      <TableCell>Voted At</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {position?.voters?.map((voter, index) => (
                      <TableRow key={voter.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{voter.fullname}</TableCell>
                        <TableCell>{voter.profile}</TableCell>
                        <TableCell>{formatDateTime(voter.votedAt)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        ))}
    </Box>
  );
}

export default VoteLog;
