import React from "react";
import { Box, Card, CardContent, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

function ElectionCard({ election }) {
  return (
    <Card>
      <CardContent>
        <Box>
          <Typography variant="cardTitle">
            {election?.electionacronym}
          </Typography>
          <Typography>{election?.electionname}</Typography>

          <Link
            to={`/publishelection/${election?.electionid}`}
            variant="body2"
            style={{ textDecoration: "none" }}>
            <Button size="small">Publish</Button>
          </Link>

          <Link
            to={`/addpositions/${election?.electionid}`}
            variant="body2"
            style={{ textDecoration: "none" }}>
            <Button size="small"> Positions</Button>
          </Link>
          <Link
            to={`/uploadvoters/${election?.electionid}`}
            variant="body2"
            style={{ textDecoration: "none" }}>
            <Button size="small">Voters</Button>
          </Link>
          <Link
            to={`/electionresults/${election?.electionid}`}
            variant="body2"
            style={{ textDecoration: "none" }}>
            <Button size="small">Results</Button>
          </Link>
        </Box>
        {/* <Box
          component="img"
          //   sx={{ width: "100%", height: "100px" }}
          // src={election?.captionimage}
        /> */}
      </CardContent>
    </Card>
  );
}

export default ElectionCard;
