// MainContent.js
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Iguana from "../pages/assets/iguana.jpeg";
import Grid from "@mui/material/Grid";
import { useNavigate, Link } from "react-router-dom";
import axiosInstance from "../utils/apiCall";
import ElectionCard from "./ElectionCard";
import { CircularProgress } from "@mui/material";
import { decodeTokenFromStorage } from "../utils/token";

const Dash = () => {
  const nav = useNavigate();
  const [myElections, setMyElections] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = decodeTokenFromStorage();

  useEffect(() => {
    setLoading(true);
    const fetchMyElections = async () => {
      try {
        const response = await axiosInstance.get(`/myelections`);
        setMyElections(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchMyElections();
  }, []);

  return (
    <Grid item xs={10}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={3}>
          {myElections.map((election, index) => (
            <Grid item key={index}>
              <ElectionCard election={election} />
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default Dash;
