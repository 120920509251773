import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../utils/apiCall";

export const useGetVoters = (electionid, page, limit) => {
  return useQuery({
    queryKey: ["allvoters", page, limit], // Include page in the queryKey
    queryFn: () =>
      axiosInstance
        .get(`/electionvoters/${electionid}?page=${page}&pageSize=${limit}`)
        .then((res) => res.data),
  });
};
