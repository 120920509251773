// MainContent.js
import React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import moment from "moment";

import Iguana from "../pages/assets/iguana.jpeg";

export default function ElectionCardVoters({ electionDetails }) {
  const {
    electionacronym,
    electionname,
    captionimage,
    electionid,
    published,
    startdate,
    enddate,
  } = electionDetails;

  const currentTime = moment();
  const formattedStartDate = moment(startdate).format("YYYY-MM-DD HH:mm");
  const formattedEndDate = moment(enddate).format("YYYY-MM-DD HH:mm");
  const formattedCurrentTime = moment(currentTime).format("YYYY-MM-DD HH:mm");

  let buttonText = "";
  let buttonVariant = "outlined";

  if (!published) {
    buttonText = "VOTING NOT PUBLISHED";
  } else if (formattedCurrentTime < formattedStartDate) {
    buttonText = "VOTING IS YET TO START";
  } else if (formattedCurrentTime > formattedEndDate) {
    buttonText = "VOTING IS OVER";
  } else {
    buttonText = "VOTE NOW";
    buttonVariant = "contained";
  }

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        alt="green iguana"
        height="140"
        image={captionimage}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {electionacronym}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {electionname}
        </Typography>
        <Typography gutterBottom variant="h5" component="div">
          Vote Starts {startdate}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Vote Ends {moment(enddate).format("YYYY-MM-DD HH:mm")}
        </Typography>
      </CardContent>
      <CardActions>
        <Link
          to={`/votesession/${electionid}`}
          variant="body2"
          style={{ textDecoration: "none" }}>
          <Button size="small" variant={buttonVariant}>
            {buttonText}
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
}
