// App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import Home from "./pages/Home";
import AdminLogin from "./pages/AdminLogin";
import AdminRegister from "./pages/AdminRegister";
import Dashboard from "./pages/Dashboard";
import { AppBar, Toolbar, Typography } from "@mui/material";
import ForgotPassword from "./pages/ForgotPassword";
import ActivateAccount from "./pages/ActivateAccount";
import AddCandidate from "./pages/AddCandidate";
import CreateElection from "./pages/CreateElection";
import AddPositions from "./pages/AddPositions";
import CreateVotes from "./pages/CreateVotes";
import ResultChart from "./pages/ResultChart";
import PrivateRoute from "./auth/PrivateRoute";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BrowseError from "./pages/BrowseError";
import VotersLogin from "./pages/VotersLogin";
import UploadVoters from "./pages/UploadVoters";
import PublishElection from "./pages/PublishElection";
import MyHome from "./pages/MyHome";
import VoteSession from "./pages/VoteSession";
import ElectionResults from "./pages/ElectionResults";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Header from "./components/Header";
import Vt from "./pages/Vt";
import VoteIssue from "./pages/VoteIssue";
import VoteThanks from "./components/VoteThanks";

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ToastContainer />
        {/* <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div">
              VOTERZ
            </Typography>
            <NavLink
              to="/"
              style={{
                textDecoration: "none",
                marginLeft: "20px",
                color: "white",
              }}
            >
              Home
            </NavLink>
            <NavLink
              to="/adminlogin"
              style={{
                textDecoration: "none",
                marginLeft: "20px",
                color: "white",
              }}
            >
              Admin Login
            </NavLink>
            <NavLink
              to="/adminregister"
              style={{
                textDecoration: "none",
                marginLeft: "20px",
                color: "white",
              }}
            >
              Admin Register
            </NavLink>
            <NavLink
              to="/dashboard"
              style={{
                textDecoration: "none",
                marginLeft: "20px",
                color: "white",
              }}
            >
              Dashboard
            </NavLink>
            <NavLink
              to="/activateaccount"
              style={{
                textDecoration: "none",
                marginLeft: "20px",
                color: "white",
              }}
            >
              Activate
            </NavLink>
            <NavLink
              to="/addcandidate"
              style={{
                textDecoration: "none",
                marginLeft: "20px",
                color: "white",
              }}
            >
              Add Candidates
            </NavLink>
            <NavLink
              to="/createelection"
              style={{
                textDecoration: "none",
                marginLeft: "20px",
                color: "white",
              }}
            >
              Create Election
            </NavLink>
            <NavLink
              to="/addpositions"
              style={{
                textDecoration: "none",
                marginLeft: "20px",
                color: "white",
              }}
            >
              Add Positions
            </NavLink>
            <NavLink
              to="/createvotes"
              style={{
                textDecoration: "none",
                marginLeft: "20px",
                color: "white",
              }}
            >
              Vote Now
            </NavLink>
            <NavLink
              to="/resultchart"
              style={{
                textDecoration: "none",
                marginLeft: "20px",
                color: "white",
              }}
            >
              Result Chart
            </NavLink>
            <NavLink
              to="/uploadvoters/:electionid"
              style={{
                textDecoration: "none",
                marginLeft: "20px",
                color: "white",
              }}
            >
              Upload Voters
            </NavLink>
          </Toolbar>
        </AppBar> */}
        <Header />
        <main>
          <Routes>
            {/* Accessible routes */}
            <Route index element={<Home />} />
            <Route path="adminlogin" element={<AdminLogin />} />
            <Route
              path="dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route path="voterslogin" element={<VotersLogin />} />
            <Route path="adminregister" element={<AdminRegister />} />
            <Route path="forgotpassword" element={<ForgotPassword />} />
            <Route path="activateaccount" element={<ActivateAccount />} />
            <Route path="error404" element={<BrowseError />} />
            <Route path="myhome" element={<MyHome />} />
            {/* Protected routes */}
            <Route
              path="dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="addcandidate/:electionid/:positionid"
              element={
                <PrivateRoute>
                  <AddCandidate />
                </PrivateRoute>
              }
            />
            <Route
              path="createelection"
              element={
                <PrivateRoute>
                  <CreateElection />
                </PrivateRoute>
              }
            />
            <Route
              path="addpositions/:electionid"
              element={
                <PrivateRoute>
                  <AddPositions />
                </PrivateRoute>
              }
            />
            <Route
              path="publishelection/:electionid"
              element={
                <PrivateRoute>
                  <PublishElection />
                </PrivateRoute>
              }
            />
            <Route
              path="createvotes"
              element={
                <PrivateRoute>
                  <CreateVotes />
                </PrivateRoute>
              }
            />
            <Route
              path="resultchart"
              element={
                <PrivateRoute>
                  <ResultChart />
                </PrivateRoute>
              }
            />
            <Route
              path="uploadvoters/:electionid"
              element={
                <PrivateRoute>
                  <UploadVoters />
                </PrivateRoute>
              }
            />
            <Route
              path="myhome/:electionid"
              element={
                <PrivateRoute>
                  <MyHome />
                </PrivateRoute>
              }
            />
            <Route
              path="votesession/:electionid"
              element={
                <PrivateRoute>
                  <VoteSession />
                </PrivateRoute>
              }
            />
            <Route path="/vt/:allbit/:longbit" element={<Vt />} />
            <Route path="/voteissue" element={<VoteIssue />} />

            <Route
              path="electionresults/:electionid"
              element={
                <PrivateRoute>
                  <ElectionResults />
                </PrivateRoute>
              }
            />
            <Route path="votethanks/:electionid" element={<VoteThanks />} />
          </Routes>
        </main>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
