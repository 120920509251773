import Sidebar from "../components/SideBar";
import { Box, Grid } from "@mui/material";
import Dash from "../components/Dash";

export default function Dashboard() {
  return (
    <Box sx={{ flexGrow: 1, marginTop: 2, padding: 4 }}>
      <Grid container spacing={2}>
        <Sidebar />
        <Dash />
      </Grid>
    </Box>
  );
}
