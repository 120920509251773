import React, { useState, useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import {
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  IconButton,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material/";

import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import axiosInstance from "../utils/apiCall";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PositionTable from "../components/PositionTable";

const AddPositions = () => {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { electionid } = useParams();
  const [electionDetails, setElectionDetails] = useState("");

  useEffect(() => {
    const fetchMyElection = async () => {
      try {
        const response = await axiosInstance.get(
          `/electiondetails/${electionid}`
        );
        setElectionDetails(response.data);
      } catch (error) {
        // nav("/error404");
      }
    };

    fetchMyElection();
  }, [electionid]);

  const { control, register, handleSubmit, watch } = useForm({
    defaultValues: {
      positions: [{ positionname: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "positions",
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true); // Start CircularProgress
      data.electionid = electionid;
      const response = await axiosInstance.post(`/addpositions/${electionid}`, {
        positions: data.positions,
      });
      toast.success("Added successfully");
      setIsLoading(false);
    } catch (error) {
      toast.error("Error adding positions");
      setIsLoading(false); // Stop CircularProgress
    }
  };

  return (
    <Container>
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" component="h2" gutterBottom>
            {electionDetails?.electionname}
          </Typography>
          <Typography variant="h4" component="h2" gutterBottom>
            Add Positions
          </Typography>
          <PositionTable electionid={electionid} />
          <form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((item, index) => (
              <Card
                variant="outlined"
                key={item.id}
                style={{ margin: "10px 0" }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        name={`positions[${index}].positionname`}
                        control={control}
                        defaultValue={item.positionname}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={`Position #${index + 1}`}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <IconButton
                    type="button"
                    onClick={() => remove(index)}
                    style={{ float: "right" }}>
                    <DeleteIcon />
                  </IconButton>
                </CardContent>
              </Card>
            ))}
            <Button
              type="button"
              variant="contained"
              sx={{ backgroundColor: "green", padding: 1 }}
              onClick={() => append({ positionname: "" })}>
              <AddIcon /> Add Position
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3, mb: 2, backgroundColor: "green", padding: 1 }}>
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddPositions;
