import React, { useState, useEffect } from "react";
import VoteResult from "../components/VoteResult";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../utils/apiCall";
import { toast } from "react-toastify";

const ResultChart = () => {
  const { electionid } = useParams();
  const [resultRecords, setResultRecords] = useState([]);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        //  setLoading(true);
        const response = await axiosInstance.get(`/votingstatus/${electionid}`);
        setResultRecords(response.data);
      } catch (error) {
        toast.error("Error getting election results.");
      }
    };

    fetchResults();
  }, [electionid]);

  const resultArray = [
    {
      positionid: 1,
      position: "President",
      scores: [
        { candidateid: 2, candidatename: "Uchenna Okey", totalvotes: 20 },
        { candidateid: 6, candidatename: "Taieo Israel", totalvotes: 130 },
        { candidateid: 90, candidatename: "Mefor Uju", totalvotes: 8 },
      ],
    },
    {
      positionid: 3,
      position: "Vice President",
      scores: [
        { candidatename: "Fisto Okey", totalvotes: 212 },
        { candidatename: "Kenny Rogers", totalvotes: 102 },
        { candidatename: "Farmer Josh", totalvotes: 298 },
      ],
    },
  ];

  return (
    <div>
      <h1>Vote Results</h1>
      <VoteResult positions={resultRecords} />
    </div>
  );
};

export default ResultChart;
