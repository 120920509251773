import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const VoteResults = ({ positions }) => {
  // Use useRef to keep track of chart instances
  const chartInstances = useRef([]);

  useEffect(() => {
    // Destroy previous chart instances
    chartInstances.current.forEach((chart) => {
      chart.destroy();
    });

    // Clear the array
    chartInstances.current = [];

    positions?.forEach((position) => {
      const canvasId = `chart-${position?.positionid}`;

      const ctx = document.getElementById(canvasId).getContext("2d");
      const newChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: position?.scores?.map((score) => score?.candidatename),
          datasets: [
            {
              label: "Total Votes",
              data: position?.scores?.map((score) => score?.totalvotes),
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
        },
      });

      // Save the chart instance
      chartInstances.current.push(newChart);
    });

    // Cleanup function to destroy charts when the component unmounts
    return () => {
      chartInstances.current.forEach((chart) => {
        chart.destroy();
      });
    };
  }, [positions]);

  return (
    <div>
      {positions?.map((position) => (
        <div key={position?.positionid}>
          <h2>{position?.position}</h2>
          <canvas
            id={`chart-${position?.positionid}`}
            width="400"
            height="200"></canvas>
        </div>
      ))}
    </div>
  );
};

export default VoteResults;
