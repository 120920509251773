import React, { useState } from "react";
import {
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  IconButton,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material/";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import axiosInstance from "../utils/apiCall";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { addVotersSchema } from "../utils/validation";
import { yupResolver } from "@hookform/resolvers/yup";

const AddVoters = () => {
  const { electionid } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const { control, register, handleSubmit, watch, setValue } = useForm({
    resolver: yupResolver(addVotersSchema),
    defaultValues: {
      voters: [
        {
          fullname: "",
          email: "",
          idnumber: "",
          phonenumber: "",
          profile: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "voters",
  });
  const onSubmit = async (data) => {
    try {
      setIsLoading(true); // Start CircularProgress

      // Transform data to match the expected structure
      const votersData = data.voters.map((voter) => ({
        fullname: voter.fullname,
        email: voter.email,
        idnumber: voter.idnumber,
        phonenumber: voter.phonenumber,
        profile: voter.profile,
      }));

      await axiosInstance.post(`/addvoters/${electionid}`, {
        voters: votersData,
      });

      toast.success("Added successfully");
      setIsLoading(false); // Stop CircularProgress
    } catch (error) {
      toast.error("Error adding voters");
      setIsLoading(false); // Stop CircularProgress
    }
  };

  return (
    <Container>
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" component="h2" gutterBottom>
            Add Voters
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((item, index) => (
              <Card
                variant="outlined"
                key={item.id}
                style={{ margin: "10px 0" }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        name={`voters[${index}].fullname`}
                        control={control}
                        defaultValue={item.fullname}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={`Fullname #${index + 1}`}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name={`voters[${index}].email`}
                        control={control}
                        defaultValue={item.email}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={`Email #${index + 1}`}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name={`voters[${index}].idnumber`}
                        control={control}
                        defaultValue={item.idnumber}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={`Identity Number #${index + 1}`}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name={`voters[${index}].phonenumber`}
                        control={control}
                        defaultValue={item.phonenumber}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={`Phone number #${index + 1}`}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name={`voters[${index}].profile`}
                        control={control}
                        defaultValue={item.profile}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={`Voters Profile #${index + 1}`}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <IconButton
                    type="button"
                    onClick={() => remove(index)}
                    style={{ float: "right" }}>
                    <DeleteIcon />
                  </IconButton>
                </CardContent>
              </Card>
            ))}
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => append({})}>
              <AddIcon /> Add another form
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3, mb: 2 }}>
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddVoters;
