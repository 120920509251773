import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  TextField,
  Container,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material/";

import { validationActivationCodeSchema } from "../utils/validation";
import axiosInstance from "../utils/apiCall";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ActivateAccount = (props) => {
  const nav = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationActivationCodeSchema),
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true); // Start CircularProgress

      // Simulate an API call delay
      const response = await axiosInstance.patch(`/activateaccount`, data);
      toast.success(response.data.message);
      nav("/adminlogin");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false); // Stop CircularProgress
    }
  };

  return (
    <Container>
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            backgroundColor: "#EEEEEE",
            padding: 20,
          }}>
          <Typography variant="h4" component="h4" gutterBottom>
            Enter Activation code
          </Typography>
          <Typography variant="h6" component="h6" gutterBottom>
            {email}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" {...register("email")} defaultValue={email} />

            <TextField
              label="Activation Code"
              type="text"
              {...register("activationcode")}
              variant="outlined"
              margin="normal"
              fullWidth
              error={!!errors.activationcode}
              helperText={errors.activationcode?.message}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isSubmitting}
              sx={{ mt: 3, mb: 2, backgroundColor: "green" }}>
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ActivateAccount;
