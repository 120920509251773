import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../utils/apiCall";

const VoteThanks = () => {
  const [viewResult, setViewResult] = useState(false);
  const { electionid } = useParams();

  useEffect(() => {
    const fetchVotingStatus = async () => {
      try {
        const response = await axiosInstance.get(
          `/resultviewstatus/${electionid}`
        );

        setViewResult(response.data.viewresult);
      } catch (error) {
        //
      }
    };
    fetchVotingStatus();
  }, [electionid]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}>
      <Typography variant="h1" color="success" gutterBottom>
        Congratulations!
      </Typography>
      <Typography variant="h5" gutterBottom>
        You have completed the voting process.
      </Typography>

      {viewResult && (
        <Button
          variant="contained"
          color="warning"
          component={Link}
          to={`/voterselectionresults/${electionid}`}
          size="large">
          View Election Result
        </Button>
      )}
    </Box>
  );
};

export default VoteThanks;
