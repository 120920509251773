import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  TextField,
  Container,
  Grid,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material/";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../utils/apiCall";
import { formatDateTime } from "../utils/dateFormat";
import { CreateElectionValidationSchema } from "../utils/validation";
import { toast } from "react-toastify";
import { newRequestUpload } from "../utils/apiStorage";

const CreateElection = () => {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(CreateElectionValidationSchema),
  });

  const handleImageChange = async (e) => {
    // Update selectedImage state when a new image is selected
    setSelectedImage(e.target.files[0]);
  };

  const getURL = async () => {
    const formData = new FormData();
    formData.append("myimage", selectedImage);

    return newRequestUpload.put(`/scavengeimage`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const postBackend = async (data) => {
    return axiosInstance.post(`/createelection`, data);
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const storageServerResponse = await getURL();
      const captionimage = storageServerResponse.data;

      // Once the image URL is obtained, proceed with the form submission
      data.startdate = formatDateTime(data.startdate);
      data.enddate = formatDateTime(data.enddate);
      data.captionimage = captionimage;

      await postBackend(data);
      toast.success("Election created successfully");
      nav("/dashboard");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{ backgroundColor: "green" }}>
          Create Election
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Election Name"
            type="text"
            {...register("electionname")}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.electionname}
            helperText={errors.electionname?.message}
          />

          <TextField
            label="Election Acronym"
            type="text"
            {...register("electionacronym")}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.electionacronym}
            helperText={errors.electionacronym?.message}
          />
          {selectedImage && (
            <img
              src={URL.createObjectURL(selectedImage)}
              alt="Selected"
              style={{ maxWidth: "100%", marginTop: "10px" }}
            />
          )}
          <TextField
            type="file"
            fullWidth
            onChange={handleImageChange}
            accept="image/*"
          />

          {/* MUI DatePicker for start date */}
          <TextField
            label="Start Date"
            type="datetime-local"
            {...register("startdate")}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.startdate}
            helperText={errors.startdate?.message}
          />

          {/* MUI DatePicker for end date */}
          <TextField
            label="End Date"
            type="datetime-local"
            {...register("enddate")}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.enddate}
            helperText={errors.enddate?.message}
          />

          <TextField
            label="General Instruction"
            multiline
            rows={5}
            {...register("generalinstruction")}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.generalinstruction}
            helperText={errors.generalinstruction?.message}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3, mb: 2 }}>
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Create Election"
            )}
          </Button>
        </form>
        <Grid container>
          <Grid item xs>
            <Link
              to="/adminlogin"
              variant="body2"
              style={{ textDecoration: "none" }}>
              Need Help?
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default CreateElection;
