import React from "react";
import { Navigate } from "react-router-dom";
import { decodeTokenFromStorage, getToken } from "../utils/token";

function PrivateRoute({ children }) {
  let isTokenAvailable = getToken();

  if (!isTokenAvailable) {
    return <Navigate to="/adminlogin" replace />;
  }
  return children;
}

export default PrivateRoute;
