import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import { adminLoginValidationSchema } from "../utils/validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axiosInstance from "../utils/apiCall";
import { useState } from "react";
import { toast } from "react-toastify";
import { setToken } from "../utils/token";

export default function AdminLogin() {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(adminLoginValidationSchema),
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true); // Start CircularProgress

      // Simulate an API call delay
      const response = await axiosInstance.post(`/adminlogin`, data);
      toast.success("Login successful");
      setToken(response?.data?.token);
      nav("/dashboard");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#EEEEEE",
          padding: 5,
        }}>
        <Typography component="h1" variant="h5">
          Admin Login
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            {...register("password")}
            error={!!errors.password}
            helperText={errors.password?.message}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />

          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={isSubmitting}
            sx={{
              mt: 3,
              mb: 2,
              bgcolor: "green",
              borderRadius: 5,
              padding: 1,
            }}>
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Sign In"
            )}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                to="/forgotpassword"
                variant="body2"
                style={{ textDecoration: "none", color: "black" }}>
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link
                to="/adminregister"
                variant="body2"
                style={{ textDecoration: "none", color: "green" }}>
                Register
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
