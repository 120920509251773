import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Container,
  Grid,
  CardMedia,
  Divider,
} from "@mui/material/";
import axiosInstance from "../utils/apiCall";

const positionsData = [
  {
    positionid: 1,
    positionname: "President",
    candidates: [
      {
        candidateid: 34,
        fullname: "Babajide",
        profilepicture:
          "https://staff.yabatech.edu.ng/staffimgg/1582018875mypass.jpg",
      },
      {
        candidateid: 48,
        fullname: "Chuckwu",
        profilepicture:
          "https://staff.yabatech.edu.ng/staffimgg/1582018875mypass.jpg",
      },
    ],
  },
  {
    positionid: 2,
    positionname: "Vice President",
    candidates: [
      {
        candidateid: 12,
        fullname: "Simisolu",
        profilepicture:
          "https://staff.yabatech.edu.ng/staffimgg/1582018875mypass.jpg",
      },
      {
        candidateid: 120,
        fullname: "Dejo Tufulu",
        profilepicture:
          "https://staff.yabatech.edu.ng/staffimgg/1582018875mypass.jpg",
      },
    ],
  },
  {
    positionid: 3,
    positionname: "Auditor",
    candidates: [
      {
        candidateid: 129,
        fullname: "Shepard",
        profilepicture:
          "https://staff.yabatech.edu.ng/staffimgg/1582018875mypass.jpg",
      },
      {
        candidateid: 2490,
        fullname: "Farmer Stone",
        profilepicture:
          "https://staff.yabatech.edu.ng/staffimgg/1582018875mypass.jpg",
      },
    ],
  },
];

const CreateVotes = () => {
  const [selectedVotes, setSelectedVotes] = useState([]);
  const [currentPositionIndex, setCurrentPositionIndex] = useState(0);

  const handleNext = () => {
    // Save the selected vote for the current position
    const selectedCandidate = positionsData[
      currentPositionIndex
    ].candidates.find((candidate) => candidate.selected);

    if (selectedCandidate) {
      const selectedVote = {
        positionid: currentPositionIndex + 1,
        candidateid: selectedCandidate.candidateid,
      };

      setSelectedVotes((prevVotes) => {
        // Remove existing object with the same positionid
        const updatedVotes = prevVotes.filter(
          (vote) => vote.positionid !== selectedVote.positionid
        );

        // Add the new selectedVote
        return [...updatedVotes, selectedVote];
      });

      // Move to the next position
      setCurrentPositionIndex((prevIndex) => prevIndex + 1);
    } else {
      alert("Please select a candidate before moving to the next position.");
    }
  };

  const handlePrevious = () => {
    // Move to the previous position
    setCurrentPositionIndex((prevIndex) => prevIndex - 1);
  };

  const handleBackToStart = () => {
    // Nullify the selected options and update the array
    const updatedPositionsData = positionsData.map((position, index) => ({
      ...position,
      candidates: position.candidates.map((candidate, candidateIndex) => ({
        ...candidate,
        selected: false,
      })),
      positionid: index + 1, // Overwrite positionid
    }));

    setCurrentPositionIndex(0);
    setSelectedVotes([]);
  };

  const handleVote = (positionIndex, candidateIndex) => {
    const updatedPositionsData = [...positionsData];
    const tempVotes = [...selectedVotes]; // Copy existing selectedVotes

    updatedPositionsData[positionIndex].candidates.forEach(
      (candidate, index) => {
        candidate.selected = index === candidateIndex;

        // If the current candidate is selected, update the tempVotes array
        if (candidate.selected) {
          const vote = {
            positionid: positionsData[positionIndex].positionid,
            candidateid: candidate.candidateid,
          };

          // Check if this vote already exists in tempVotes
          const existingVoteIndex = tempVotes.findIndex(
            (v) =>
              v.positionid === vote.positionid &&
              v.candidateid === vote.candidateid
          );

          // If not, add it to the tempVotes array
          if (existingVoteIndex === -1) {
            tempVotes.push(vote);
          }
        }
      }
    );

    setCurrentPositionIndex(positionIndex);
    setSelectedVotes(tempVotes);
  };

  const handleSubmit = async () => {
    try {
      // Ensure the current position index is within the array bounds
      if (
        currentPositionIndex >= 0 &&
        currentPositionIndex < positionsData.length
      ) {
        // Save the selected vote for the last position
        const selectedCandidate = positionsData[
          currentPositionIndex
        ]?.candidates.find((candidate) => candidate.selected);

        if (selectedCandidate) {
          setSelectedVotes((prevVotes) => [
            ...prevVotes,
            {
              positionid: positionsData[currentPositionIndex].positionid,
              candidateid: selectedCandidate.candidateid,
            },
          ]);

          // Use Axios to make the API request
          const response = await axiosInstance.put(
            `createvotes`,
            selectedVotes
          );

          if (response.data.success) {
            alert("Votes submitted successfully");
          } else {
            alert("Error submitting votes");
          }
        } else {
          alert("Please select a candidate before submitting.");
        }
      }
    } catch (error) {
      alert("Error submitting votes");
    }
  };

  return (
    <Container>
      {currentPositionIndex < positionsData.length ? (
        <Grid
          container
          spacing={3}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" component="h2" gutterBottom>
              {positionsData[currentPositionIndex].positionname}
            </Typography>
            <Card style={{ maxWidth: 400, margin: "auto" }}>
              <CardContent>
                {positionsData[currentPositionIndex].candidates.map(
                  (candidate, index) => (
                    <div key={candidate.candidateid}>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: "25px" }}>
                        <Grid item xs={4}>
                          <CardMedia
                            component="img"
                            alt={candidate.fullname}
                            height="100%"
                            width="100%"
                            image={candidate.profilepicture}
                            style={{
                              borderRadius: "50%",
                              border: "2px solid #ccc",
                            }}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Typography style={{ marginTop: "8px" }}>
                            {candidate.fullname}
                          </Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleVote(currentPositionIndex, index)
                            }
                            disabled={candidate.selected}
                            style={{ marginTop: "15px" }}>
                            {candidate.selected ? "Selected" : "Select"}
                          </Button>
                        </Grid>
                      </Grid>
                      {index <
                        positionsData[currentPositionIndex].candidates.length -
                          1 && <Divider style={{ margin: "10px" }} />}
                    </div>
                  )
                )}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handlePrevious}
                      disabled={currentPositionIndex === 0}>
                      Previous
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}>
                      {currentPositionIndex === positionsData.length - 1
                        ? "Submit"
                        : "Next"}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          spacing={3}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" component="h2" gutterBottom>
              Submit Votes
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleBackToStart}>
                  Check Selections
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}>
                  Submit finally
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default CreateVotes;
