import React, { useState, useEffect } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axiosInstance from "../utils/apiCall";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import { CardMedia, Card } from "@mui/material";

const CandidateTable = () => {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedCandidate, setSelectedCandidate] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const { electionid } = useParams();
  const { positionid } = useParams();
  const [myPositions, setMyPositions] = useState([]);
  const [myCandidates, setMyCandidates] = useState([]);
  const [candidateData, setCandidateData] = useState({
    fullname: "",
    profile: "",
    candidateid: "",
  });
  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const response = await axiosInstance.get(
          `/candidates/${electionid}/${positionid}`
        );
        setMyCandidates(response.data);
      } catch (error) {
        // nav("/error404");
      }
    };

    fetchCandidates();
  }, [positionid]);

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await axiosInstance.get(`/positions/${electionid}`);
        setMyPositions(response.data);
      } catch (error) {
        //  nav("/error404");
      }
    };

    fetchPositions();
  }, [electionid]);

  const handleEditClick = (candidate, id) => {
    const selectedCandidateData = myCandidates?.find(
      (pos) => pos.fullname === candidate
    );
    setSelectedCandidate(candidate);

    setCandidateData((prevData) => ({
      ...prevData,
      fullname: selectedCandidateData?.fullname || "",
      profile: selectedCandidateData?.profile || "",
      candidateid: selectedCandidateData?.candidateid || "",
    }));

    setEditModalOpen(true);
  };

  const handleDeleteClick = (data, candidate, id) => {
    setSelectedCandidate(data?.candidateid);
    setSelectedId(id);
    setDeleteModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDeleteConfirm = async () => {
    // Handle delete logic here

    try {
      setIsLoading(true);
      // Add logic to send data to the endpoint
      const response = await axiosInstance.delete(
        `/deletecandidate/${selectedCandidate}/${positionid}/${electionid}`
      );
      setIsLoading(false);
      toast.success(response.data);

      // Close the modal
      setEditModalOpen(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Delete failed");
    }

    setDeleteModalOpen(false);

    setDeleteModalOpen(false);
  };

  const handleInputChange = (e) => {
    setCandidateData({
      ...candidateData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditSubmit = async () => {
    try {
      setIsLoading(true);
      // Add logic to send data to the endpoint
      const response = await axiosInstance.patch(
        `/editcandidate/${candidateData.candidateid}/${positionid}/${electionid}`,
        {
          fullname: candidateData.fullname,
          profile: candidateData.profile,
        }
      );
      setIsLoading(false);
      toast.success(response.data);

      // Close the modal
      setEditModalOpen(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Candidate Edit failed");
    }
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S/N</TableCell>
              <TableCell>Candidate</TableCell>
              <TableCell>Profile</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {myCandidates.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <Card>
                    <CardMedia
                      component="img"
                      alt="Image"
                      height="140"
                      image={row.captionimage}
                    />
                  </Card>
                </TableCell>
                <TableCell>{row.fullname}</TableCell>
                <TableCell>{row.profile}</TableCell>

                <TableCell>
                  <Button
                    onClick={() =>
                      handleEditClick(row?.fullname, row.positionid)
                    }>
                    <EditIcon />
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() =>
                      handleDeleteClick(row, row.fullname, row.positionid)
                    }>
                    <DeleteIcon sx={{ color: "red" }} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Modal */}
      <Dialog open={editModalOpen} onClose={handleEditModalClose}>
        <DialogTitle>Edit Candidate</DialogTitle>
        <DialogContent>
          <form>
            <input
              type="hidden"
              name="positionid"
              value={candidateData.candidateid}
            />
            <TextField
              label="Candidate Fullname"
              name="fullname"
              value={candidateData.fullname}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Candidate Profile"
              name="profile"
              value={candidateData.profile}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditModalClose}>Cancel</Button>
          <Button onClick={handleEditSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Modal */}
      <Dialog open={deleteModalOpen} onClose={handleDeleteModalClose}>
        <DialogTitle>Delete Candidate</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete candidate "{selectedCandidate}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteModalClose}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CandidateTable;
