import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  TextField,
  Container,
  Grid,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material/";
import { adminRegisterValidationSchema } from "../utils/validation";
import { useState } from "react";
import axiosInstance from "../utils/apiCall";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";

const AdminRegister = () => {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(adminRegisterValidationSchema),
  });

  const onSubmit = async (data) => {
    let email = data.email;
    try {
      setIsLoading(true); // Start CircularProgress

      // Simulate an API call delay
      const response = await axiosInstance.post(`/createaccount`, data);
      toast.success(response.data.message);
      nav("/activateaccount", { state: { email } });
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false); // Stop CircularProgress
    }
  };

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#EEEEEE",
          padding: 5,
        }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Create Account
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Email"
            type="text"
            {...register("email")}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.email}
            helperText={errors.email?.message}
          />

          <TextField
            label="First Name"
            type="text"
            {...register("firstname")}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.firstname}
            helperText={errors.firstname?.message}
          />

          <TextField
            label="Last Name"
            type="text"
            {...register("lastname")}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.lastname}
            helperText={errors.lastname?.message}
          />

          <TextField
            label="Password"
            type="password"
            {...register("password")}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.password}
            helperText={errors.password?.message}
          />

          <TextField
            label="Confirm Password"
            type="password"
            {...register("confirmPassword")}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
          />

          <TextField
            label="Phone Number"
            type="text"
            {...register("phonenumber")}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.phonenumber}
            helperText={errors.phonenumber?.message}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isSubmitting}
            sx={{ mt: 3, mb: 2, backgroundColor: "green", padding: 1 }}>
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Sign In"
            )}
          </Button>
        </form>
        <Grid container>
          <Grid item xs>
            <Link
              to="/forgotpassword"
              variant="body2"
              style={{ textDecoration: "none" }}>
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link
              to="/adminlogin"
              variant="body2"
              style={{ textDecoration: "none" }}>
              Already have an account? Log In
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AdminRegister;
