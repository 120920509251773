import React, { useState, useEffect } from "react";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Container,
  Typography,
  TextField,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
} from "@mui/material";
import axiosInstance from "../utils/apiCall";
import { toast } from "react-toastify";
import { uploadCsvSchema } from "../utils/validation";
import { useParams, useNavigate } from "react-router-dom";
import { useGetVoters } from "../hooks/apiFetch";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import AddVoters from "../components/addVoters";

const UploadVoters = () => {
  const nav = useNavigate();
  const { electionid } = useParams();
  const [isNowLoading, setIsNowLoading] = useState(false);
  const [loadingSendCode, setLoadingSendCode] = useState(false);
  const [theTotalVoters, setTheTotalVoters] = useState("");
  const [deleteAllLoading, setDeleteAllLoading] = useState(false);
  const [recodeLoading, setRecodeLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [theTotalCodeSent, setTheTotalCodeSent] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    setLoadingSendCode(true);
    const fetchVotersStatistics = async () => {
      try {
        const response = await axiosInstance.get(`/votersstat/${electionid}`);
        setTheTotalVoters(response?.data?.totalvoters);
        setTheTotalCodeSent(response?.data?.totalcodesent);
        setLoadingSendCode(false);
      } catch (error) {
        setLoadingSendCode(false);
      }
    };

    fetchVotersStatistics();
  }, [electionid]);

  const { isLoading, error, data, refetch } = useGetVoters(
    electionid,
    currentPage + 1,
    itemsPerPage
  );

  const handlePageClick = (direction) => {
    setCurrentPage((prevPage) => {
      if (direction === "next") {
        return prevPage + 1; // Increase the current page
      } else if (direction === "prev" && prevPage > 0) {
        return prevPage - 1; // Decrease the current page, ensuring it doesn't go below 0
      }
      return prevPage;
    });
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    setError,
  } = useForm({
    resolver: yupResolver(uploadCsvSchema),
  });

  const onSubmit = async (data) => {
    try {
      setIsNowLoading(true);
      const formData = new FormData();
      formData.append("csvFile", data.csvFile[0]);

      await axiosInstance.post(`/uploadvoters/${electionid}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("CSV file uploaded successfully!");
      setIsNowLoading(false);
      reset(); // Reset the form after successful upload
    } catch (error) {
      toast.error(error.response.data.message);
      setError("csvFile", {
        type: "manual",
        message: "Failed to upload CSV file",
      });
      setIsNowLoading(false);
    }
  };

  const handleSendCode = async (data) => {
    try {
      setLoadingSendCode(true); // Start CircularProgress

      // Simulate an API call delay
      const response = await axiosInstance.post(
        `/sendelectioncode/${electionid}`
      );
      setTheTotalVoters(response?.data?.totalvoters);
      setTheTotalCodeSent(response?.data?.totalcodesent);
      toast.success("Voting code sent to voters");
      setLoadingSendCode(false);
    } catch (error) {
      toast.error("Code sending failed");
    } finally {
      setLoadingSendCode(false); // Stop CircularProgress
    }
  };

  const handleEdit = (voterId) => {
    // Handle edit action, e.g., redirect to edit page
    // Example: nav(`/editvoter/${voterId}`);
  };

  const handleRecode = (voterId) => {
    // Handle recode action
    // Example: Implement recode logic for the specific voter
  };

  const handleDelete = async (voterId) => {
    try {
      setDeleteLoading(true); // Start CircularProgress

      // Simulate an API call delay
      const response = await axiosInstance.delete(
        `/deletevoter/${voterId}/${electionid}`
      );
      setTheTotalVoters(response?.data?.totalvoters);
      toast.success("Delete action was successful");
      setDeleteLoading(false);
    } catch (error) {
      toast.error("Delete action failed");
    } finally {
      setDeleteLoading(false); // Stop CircularProgress
    }
  };

  const handleDeleteAll = async () => {
    try {
      setDeleteAllLoading(true); // Start CircularProgress

      // Simulate an API call delay
      const response = await axiosInstance.delete(
        `/deleteelectionvoters/${electionid}`
      );
      setTheTotalVoters(response?.data?.totalvoters);
      toast.success("Delete action was successful");
      setDeleteAllLoading(false);
    } catch (error) {
      toast.error("Delete action failed");
    } finally {
      setDeleteAllLoading(false); // Stop CircularProgress
    }
  };

  return (
    <Container>
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Upload Voters CSV
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register("csvFile")}
            type="file"
            accept=".csv"
            fullWidth
            error={Boolean(errors.csvFile)}
            helperText={errors.csvFile?.message}
            sx={{ mb: 2 }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isNowLoading}
            sx={{ mt: 3, mb: 2 }}>
            {isNowLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Upload"
            )}
          </Button>
        </form>
      </Grid>
      <Grid>
        <Typography variant="h4" component="h2" gutterBottom>
          Total voters : {theTotalVoters || 0} :::::: Total voting code sent :
          {theTotalCodeSent || 0}
        </Typography>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={theTotalVoters === theTotalCodeSent}
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSendCode}>
          {loadingSendCode ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Send Election Code"
          )}
        </Button>
      </Grid>
      {theTotalCodeSent < theTotalVoters ? (
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleDeleteAll}>
          {deleteAllLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Delete voters"
          )}
        </Button>
      ) : null}

      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Fullname</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Identity Number</TableCell>
              <TableCell>Election Code Sent</TableCell>
              <TableCell>Profile</TableCell>
              <TableCell>Delete</TableCell>

              {/* Add more table headers as needed */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.voters.map((voter, index) => (
                <TableRow key={voter.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{voter?.fullname}</TableCell>
                  <TableCell>{voter?.email}</TableCell>
                  <TableCell>{voter?.idnumber}</TableCell>
                  <TableCell>{voter?.codesent ? "True" : "False"}</TableCell>
                  <TableCell>{voter?.profile}</TableCell>

                  <TableCell>
                    {deleteLoading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <IconButton
                        onClick={() => handleDelete(voter?.id)}
                        disabled={voter?.codesent}>
                        <DeleteIcon sx={{ color: "red" }} />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={data?.totalvoters || 0}
        rowsPerPage={itemsPerPage}
        page={currentPage}
        onPageChange={(event, newPage) =>
          handlePageClick(newPage > currentPage ? "next" : "prev")
        }
        nextIconButtonProps={{
          disabled: currentPage === data?.totalPages - 1,
        }}
      />

      <AddVoters electionid={electionid} />
    </Container>
  );
};

export default UploadVoters;
